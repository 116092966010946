import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import myImg from "../../Assets/avatar.svg";
import myImg from '../../Assets/me.jpeg';
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              <p>Hello, my name is <strong>Yakup Afsin</strong> and I'm a <span className="purple">React Native developer</span>. </p>
              <p>I have <strong>[Number]</strong> years of experience working with React Native and I'm passionate about building robust and engaging mobile applications. </p>
              <p>My expertise lies in developing cross-platform applications for iOS and Android devices, leveraging React Native's powerful UI library and other cutting-edge technologies.</p>

              <p>Throughout my career, I've worked on a variety of projects ranging from simple applications to complex, multi-feature projects. </p>
              <p>I'm constantly learning and staying up-to-date with the latest trends and best practices in React Native development to ensure that I'm delivering the highest quality work to my clients.</p>

              <p>If you're looking for a skilled <span className="purple">React Native developer</span> who is dedicated to creating exceptional mobile applications, I'd love to chat more about how I can help bring your ideas to life. Thank you for considering me for your next project.</p>

            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/yakupafsin"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/ykpafsn"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/ykpafsn/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ykpafsn"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
