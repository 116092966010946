import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>Hello, my name is <strong>Yakup</strong> and I'm a <span className="purple">React Native developer</span> with a passion for creating exceptional mobile applications. With <strong>6</strong> years of experience in the industry, I've worked on a wide range of projects from small-scale apps to large, complex applications.</p>
          <p>Since I start working on React Native I've been honing my skills in React Native development. I'm always looking for new challenges and opportunities to learn and grow as a developer.</p>
          <p>When I'm not coding, you can find me hiking, reading, or volunteering in my community. I believe in giving back and using my skills to make a positive impact on the world around me.</p>
          <p>If you're interested in working with me or just want to chat about mobile app development, please don't hesitate to <a href="/">get in touch</a>. I'm always happy to connect with fellow developers and potential clients!</p>


          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Yakup Afsin</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
